<template>
  <div>
    <hero-alt
      img="https://image.freepik.com/photos-gratuite/jolie-fille-travaille-ordinateur-portable-dans-cafe-hipster_346278-203.jpg"
      title="Téléchargement des notices"
      subtext="Vous y trouverez la documentation relative à votre produit."
    />

    <finder v-on:selected="referenceSelected" />

    <file-list :reference="reference" />
  </div>
</template>

<script>
export default {
  name: "Download",

  metaInfo: { title: "Téléchargement" },

  methods: {
    referenceSelected(ref) {
      console.log(ref);
      this.reference = ref;
    }
  },
  data: () => ({
    reference: ""
  }),

  components: {
    HeroAlt: () => import("@/components/mixins/HeroAlt"),
    Finder: () => import("@/components/ReferenceFinder"),
    FileList: () => import("@/components/FileList")
  }
};
</script>

<style scoped></style>
